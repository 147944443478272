var video;
$(document).ready(function() {
	var videoInterval;
	/* PJTWAUS-1_KJB 20200120 : add */
	var accVideoLinkArea="";
	var accVideoLinkName="";
	var videoLinkArea="";
	var videoLinkName="";
	/* //PJTWAUS-1_KJB 20200120 : add */
	
	function setYoutubeJS() {
		var tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";
		//var firstScriptTag = document.getElementsByTagName('script')[0];
		//firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		$('body').append(tag);
	}
	setYoutubeJS();

	video = {
		originalFocus: null,
		cache: {
			$modal: null,
			id: null
		},
		markup: {
			modal: [
				'<div class="video-modal video-box-closeset youtube"><div class="modal-video-asset"><div class="video-asset">',
				'<div class="video-modal video-box-closeset brightcove"><div class="modal-video-asset"><div class="video-asset">',
				'</div></div><button class="close-video">Close Video</button>',
				/* PJTWAUS-1 : 20191215  add */
				'</div>',				
				'<button class="acc-change-video">Play a audio description video</button>',
				'<button class="change-video">Play a non-audio description video</button>'				
				/*// PJTWAUS-1 : 20191215 add */				
			],
			current: [
				'<div class="video-asset video-box-closeset">',
				'<button class="close-video">Close Video</button>',
				/* PJTWAUS-1 : 20191215 add */
				'</div>',
				'<button class="acc-change-video">Play a audio description video</button>',
				'<button class="change-video">Play a non-audio description video</button>'								
				/*// PJTWAUS-1 : 20191215 add */	
			]
		},
		getTitle: function(videoType, video) {
			var title="";
			if(videoType=="youtube") {
				title = video.getVideoData().title;
			} else {
				title = video.mediainfo.name;
			}
			return changeTitleFormat(title);
		},
		trigger: {
			init: function(){
				var tr = video.trigger;
				tr.addEvent();
			},
			setYoutube: function(t){
				
				/* PJTWAUS-1_KJB : 20190113 : add */
				if($(t).data('video-content') == 'video'){
					videoLinkArea=$(t).siblings('.see-video').data('link-area');
					videoLinkName=$(t).siblings('.see-video').data('link-name');
				}else if($(t).data('video-content') == 'acc-video'){
					accVideoLinkArea=$(t).siblings('.see-video').data('link-area');
					accVideoLinkName=$(t).siblings('.see-video').data('link-name');
				}
				/* // PJTWAUS-1_KJB : 20190113 : add */

				var _this = t;
				var videoId = xssfilter(t.getAttribute('data-src'));
				var htmlDefault = null,
					html = null;
				if(videoId.indexOf('/') > 0) {
					var i = videoId.split('/');
					videoId = i[i.length-1];
				}
				var playsinline = (t.getAttribute('data-target') != 'modal') ? '&playsinline=1' : '';
				htmlDefault = '<div id="videoPlayerCode"></div>';
				if(t.getAttribute('data-target') == 'modal') {
					// if(video.cache.id != videoId) {
						html = video.markup.modal[0]+ htmlDefault +video.markup.modal[2];
						/* PJTWAUS-1 : 20191215 add */
						if($(t).data('video-content') == 'video') {
							html += video.markup.modal[4];
						}else if($(t).data('video-content') == 'acc-video') {
							html += video.markup.modal[5];
						}
						html +=video.markup.modal[3];
						/*// PJTWAUS-1 : 20191215 add */
					// }
					video.appendModal('youtube', html, videoId);
					this.playVideoYoutube(videoId);
				}else {
					html = video.markup.current[0]+ htmlDefault +video.markup.current[1];
					/* PJTWAUS-1 : 20191215 add */
					if($(t).data('video-content') == 'video') {
						html += video.markup.current[3];
					}else if($(t).data('video-content') == 'acc-video') {
						html += video.markup.current[4];
					}
					html +=video.markup.current[2];
					/*// PJTWAUS-1 : 20191215 add */
					video.appendCurrent('youtube', html, videoId, '', '', t);
					this.playVideoYoutube(videoId);
				}
				

			},
			setBrightcove: function(t){

				/* PJTWAUS-1_KJB : 20190113 : add */
				if($(t).data('video-content') == 'video'){
					videoLinkArea=$(t).siblings('.see-video').data('link-area');
					videoLinkName=$(t).siblings('.see-video').data('link-name');
				}else if($(t).data('video-content') == 'acc-video'){
					accVideoLinkArea=$(t).siblings('.see-video').data('link-area');
					accVideoLinkName=$(t).siblings('.see-video').data('link-name');
				}
				/* // PJTWAUS-1_KJB : 20190113 : add */

				var videoId = xssfilter(t.getAttribute('data-video-id'));
				var videoAccount = xssfilter(t.getAttribute('data-account'));
				var videoPlayer = xssfilter(t.getAttribute('data-player'));
				var htmlDefault = null,
					html = null;
				
				htmlDefault = '<div id="videoPlayerCode"></div>';
				if(t.getAttribute('data-target') == 'modal') {
					// if(video.cache.id != videoId) {
						html = video.markup.modal[1]+ htmlDefault +video.markup.modal[2];
						/* PJTWAUS-1 : 20191215 add */
						if($(t).data('video-content') == 'video') {
							html += video.markup.modal[4];
						}else if($(t).data('video-content') == 'acc-video') {
							html += video.markup.modal[5];
						}
						html +=video.markup.modal[3];
						/*// PJTWAUS-1 : 20191215 add */
					// }
					video.appendModal('brightcove', html, videoId);
					this.playVideoBrightCove(videoAccount, videoPlayer, videoId);
				}else {
					html = video.markup.current[0]+ htmlDefault +video.markup.current[1];
					/* PJTWAUS-1 : 20191215 add */
					if($(t).data('video-content') == 'video') {
						html += video.markup.current[3];
					}else if($(t).data('video-content') == 'acc-video') {
						html += video.markup.current[4];
					}
					html +=video.markup.current[2];
					/*// PJTWAUS-1 : 20191215 add */
					video.appendCurrent('brightcove', html, videoId, videoAccount, videoPlayer, t);
					this.playVideoBrightCove(videoAccount, videoPlayer, videoId);
				}
			},
			playVideoYoutube: function(vid) {
				if(vid.indexOf('?')>=0) vid = vid.split("?")[0];
				setTimeout(function() {
					var player = new YT.Player('videoPlayerCode', {
						height: '360',
						width: '640',
						videoId: vid,
						host: 'https://www.youtube.com',
						playerVars: {
							modestbranding: true, rel: 0
						},
						events: {
							'onReady': onPlayerReady,
							'onStateChange': onPlayerStateChange
						}
					});
					function onPlayerReady(event) {
						event.target.playVideo();
					}
					function onPlayerStateChange(event) {
						if (event.data == YT.PlayerState.PLAYING) {
							adobeSatellite('video_track',{name:video.getTitle('youtube', player),event:'play'});
						}
						if (event.data == YT.PlayerState.PAUSED) {
							adobeSatellite('video_track',{name:video.getTitle('youtube', player),event:'pause'});
						}
					}

					var videoStatus = [0, 0, 0, 0];
					videoInterval = setInterval(function() {
						if(typeof player.getDuration != 'function') return false;
						var a = player.getDuration();
						var c = player.getCurrentTime();
						var p = parseInt((c*100)/a);
						for(var i=1;i<=4;i++) {
							if(p>=(25*i) && videoStatus[i-1]==0) {
								videoStatus[i-1] = 1;
								adobeSatellite('video_track',{name:video.getTitle('youtube', player), event:'ratio_'+(25*i)});
							}
						}
						if(p>=99 && videoStatus[3]==0) {
							videoStatus[3]=1;
							adobeSatellite('video_track',{name:video.getTitle('youtube', player),event:'ratio_'+(25*4)});
						}
						
						/* PJTWAUS-1 20191215 add */
						/*var focusObj = $('.video-modal, .video-asset').find('a[href], area[href], input:not([disabled]), input:not([readonly]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]');						
						var focusFirst = focusObj.first();
						var focusLast = focusObj.last();
												
						focusLast.on('keydown', function (e) {
							if ((e.which === 9 && !e.shiftKey)) {
								e.preventDefault();
								console.log(focusFirst);
								focusFirst.focus();
							}
						});
						focusFirst.on('keydown', function (e) {
							console.log(e.which);
							console.log(e.shiftKey);
							if ((e.which === 9 && e.shiftKey)) {
								e.preventDefault();
								console.log(focusLast);
								focusLast.focus();
							}
						});*/
						/*// PJTWAUS-1 20191215 add */
					}, 2000);
					/* PJTWAUS-1_KJB : 20190113 : add */
					$(".change-video").attr('data-link-area',accVideoLinkArea);
					$(".change-video").attr('data-link-name',accVideoLinkName);
					
					$(".acc-change-video").attr('data-link-area',videoLinkArea);
					$(".acc-change-video").attr('data-link-name',videoLinkName);
					/* // PJTWAUS-1_KJB : 20190113 : add */
				}, 1000);
			},
			playVideoBrightCove: function(accountId, playerId, videoId) {
				var myPlayer,
					playerHTML,
					playerData = {
						accountId: accountId,
						playerId: playerId,
						videoId: videoId
					};
				playerHTML = '<video-js id="brightCovePlayer" data-video-id="' +playerData.videoId +'"  data-account="' +playerData.accountId +'" data-player="' +playerData.playerId +'" data-embed="default" data-application-id autoplay class="video-js" controls></video-js>';
				setTimeout(function() {
					document.getElementById("videoPlayerCode").innerHTML = playerHTML;
					var s = document.createElement("script");
					s.src = "https://players.brightcove.net/" + playerData.accountId + "/" + playerData.playerId + "_default/index.min.js"; 
					document.body.appendChild(s);
					s.onload = callback;
					function callback() {
						myPlayer = bc(document.getElementById('brightCovePlayer'));
						myPlayer.on("loadedmetadata", function() {
							myPlayer.muted(false);
							myPlayer.play();
						});
						myPlayer.on("pause", function() {
							adobeSatellite('video_track',{name:video.getTitle('brightcove', myPlayer),event:'pause'});
						});
						myPlayer.on("play", function() {
							adobeSatellite('video_track',{name:video.getTitle('brightcove', myPlayer),event:'play'});
						});
					};

					var videoStatus = [0, 0, 0, 0];
					videoInterval = setInterval(function() {
						var a = myPlayer.mediainfo.duration;
						var c = myPlayer.currentTime();
						var p = parseInt((c*100)/a);
						for(var i=1;i<=4;i++) {
							if(p>=(25*i) && videoStatus[i-1]==0) {
								videoStatus[i-1] = 1;
								adobeSatellite('video_track',{name:video.getTitle('brightcove', myPlayer),event:'ratio_'+(25*i)});
							}
						}
						if(p>=99 && videoStatus[3]==0) {
							videoStatus[3]=1;
							adobeSatellite('video_track',{name:video.getTitle('brightcove', myPlayer),event:'ratio_'+(25*4)});
						}
						
						/* PJTWAUS-1 20191215 add */
						/*var focusObj = $('.video-modal').find('a[href], area[href], input:not([disabled]), input:not([readonly]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]');
						var focusFirst = focusObj.first();
						var focusLast = focusObj.last();
						
						focusLast.on('keydown', function (e) {
							if ((e.which === 9 && !e.shiftKey)) {
								e.preventDefault();
								
								focusFirst.focus();
							}
						});
						focusFirst.on('keydown', function (e) {
							if ((e.which === 9 && e.shiftKey)) {
								e.preventDefault();
								
								focusLast.focus();
							}
						});*/
						/*// PJTWAUS-1 20191215 add */
					}, 2000);
					/* PJTWAUS-1_KJB : 20190113 : add */
					$(".change-video").attr('data-link-area',accVideoLinkArea);
					$(".change-video").attr('data-link-name',accVideoLinkName);
					
					$(".acc-change-video").attr('data-link-area',videoLinkArea);
					$(".acc-change-video").attr('data-link-name',videoLinkArea);
					/* // PJTWAUS-1_KJB : 20190113 : add */
				}, 1000);
			},
			addEvent: function(){
				var tr = video.trigger;
				var $component = $('.component');
				if($component.length <= 0) {
					$component = $('.container-fluid');
				}
				$component.off('click.video').on({
					'click.video': function(e){
						e.preventDefault();

						// 실행중인 비디오가 있으면 강제 종료
						if($('#videoPlayerCode').length>0) {
							var oldVideo = $('#videoPlayerCode');
							if(oldVideo.closest('.video-modal').length>0) {
								oldVideo.closest('.video-modal').find('button.close-video').trigger('click');
							} else {
								$('#videoPlayerCode').siblings('button.close-video').trigger('click');
							}
						}

						var _this = e.currentTarget;
						var unCache = true;
						video.originalFocus = $(_this);
						if(_this.getAttribute('data-target') != 'modal') {
							// is component player
							var $cv = $(_this).data('cachedvideo');
							var $ci = $(_this).data('cacheid');
							var $ca = $(_this).data('cacheaccount');
							var $cp = $(_this).data('cacheplayer');
							if($cv) {
								video.appendCurrent(_this.getAttribute('data-type'), $cv, $ci, $ca, $cp, _this, true);
								if(_this.getAttribute('data-type')=="youtube") {
									video.trigger.playVideoYoutube($ci); //PJTWAUS-1 : 20191219 modify
								} else {
									video.trigger.playVideoBrightCove($ca, $cp, $ci); //PJTWAUS-1 : 20191219 modify
								}
								unCache = false;
							}else {
								unCache = true;
							}
						}

						if(unCache && (_this.getAttribute('data-type') == 'youtube')) {
							tr.setYoutube(_this);
						}else if(unCache && (_this.getAttribute('data-type') == 'brightcove')) {
							tr.setBrightcove(_this);
						}
						
						// stop autoplay by clicking the video button
						var slick = $(_this).closest('.slick-initialized');
						if(slick.length>0) {
							slick.next().find('a.slide-pause.pause').trigger('click');
						}

					}
				}, '.see-video');
			}
		},
		appendCurrent: function(videoType, mk, id, account, player, anchor, cached){
			var html = mk;
			var $target = $(anchor).parents('.video-box');
			$target = ($target.get(0)) ? $target : $(anchor).parents('.video-box').find('.visual-area');
			var $asset = $target.find('.video-asset');
			var $invisible = $(anchor).parents('.carousel-box').find('.text-area');
			if($asset) $asset.remove();
			$invisible.animate({
				opacity: 0
			}, 500);
			$target.parents('.component').addClass('js-invisible-nav');
			// $invisible.animate({
			// 	opacity: 0
			// }, 500);
			setTimeout(function(){
				$invisible.css({
					zIndex: -1
				});
				$target.append(html);
				if(!cached) {
					// init
					video.initCompo(anchor, id, account, player); // saved cache data in anchor
				}
			}, 500);
		},
		appendModal: function(videoType, mk, id){
			var modal = document.querySelectorAll('.video-modal')[0];
			var html = mk;
			if(modal) $(modal).remove();
			// if(video.cache.id == id){ // is cached video
			// 	html = video.cache.$modal;
			// 	$('body').append(html);
			// }else { // is not cached video
			// }
			$('body').addClass('modal-open').append(html);
			$('body').find('.video-modal').attr('tabindex', 0).focus();
			video.initModal();
			video.cache.id = id;
		},
		initCompo: function(cache, vid, va, vp){
			$('.video-asset').on({
				click: function(e){
					e.preventDefault();
					var $current = $(e.delegateTarget);
					var $invisible = $(e.delegateTarget).parents('.video-box').find('.text-area');
					$current.parents('.component').removeClass('js-invisible-nav');
					$(cache).data('cachedvideo', $current.detach());
					$(cache).data('cacheid', vid);
					$(cache).data('cacheaccount', va);
					$(cache).data('cacheplayer', vp);
					// $invisible.removeAttr('style').animate({
					// 	opacity: 1
					// }, 500);
					$invisible.removeAttr('style');
				}
			}, '.close-video');
			/* PJTWAUS-1 20191215 add */			
			$('.video-asset').on({
				click: function(e){
					e.preventDefault();
					$(this).siblings('.close-video').click();
					$(cache).siblings('.video-content').click();					
				}
			}, '.change-video');
			$('.video-asset').on({
				click: function(e){
					e.preventDefault();
					$(this).siblings('.close-video').click();
					$(cache).siblings('.acc-video-content').click();
				}
			}, '.acc-change-video');
			/*// PJTWAUS-1 20191215 add */
		},
		initModal: function(){
			$('.video-modal').on({
				click: function(e){
					e.preventDefault();
					var $currentModal = $(e.delegateTarget);
					video.cache.$modal = $currentModal.detach();
					$('body').removeClass('modal-open');
					video.originalFocus.focus();
					if(videoInterval) clearInterval(videoInterval);
				}
			}, '.close-video');		
			/* PJTWAUS-1 20191215 add */			
			$('.video-modal').on({
				click: function(e){
					e.preventDefault();
					$(this).siblings('.close-video').click();
					video.originalFocus.siblings('.video-content').click();
				}
			}, '.change-video');
			$('.video-modal').on({
				click: function(e){
					e.preventDefault();
					$(this).siblings('.close-video').click();
					video.originalFocus.siblings('.acc-video-content').click();
				}
			}, '.acc-change-video');
			/*// PJTWAUS-1 20191215 add */
		},
		init: function(){
			video.trigger.init();
			// video.addEvent();
		}
	};

	video.init();
});
